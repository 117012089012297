import React from 'react'

import {
	AccessEventIcon,
	DashboardEventIcon,
	IntegrationEventIcon,
	ParticipantEventIcon,
	PencilIcon,
	SaleEventIcon
} from 'pages/Event/Icons/Icons'

export const SalesNav = [
	{
		tab: 'participants',
		trans: 'event.participantsTitle',
		icon: <ParticipantEventIcon />
	},
	{
		tab: 'integrations',
		trans: 'event.integrationsTitle',
		icon: <IntegrationEventIcon />
	},
	{
		tab: 'statistic',
		trans: 'event.statisticTitle',
		icon: <DashboardEventIcon />
	}
]
export const VolunteerNav = [SalesNav[0]]
export const OperatorNav = [
	{
		tab: 'description',
		trans: 'event.description',
		icon: <PencilIcon fill={'var(--accent-color)'} />
	},
	{
		tab: 'promoAndDiscount',
		trans: 'event.ticketsTitle',
		icon: <SaleEventIcon />
	}
]
export const AccessNav = {
	tab: 'access',
	trans: 'event.accessTitle',
	icon: <AccessEventIcon />
}
