import React from 'react'

export default function Logo() {
	return (
		<svg width="153" height="35" viewBox="0 0 153 35" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect
				width="19.7535"
				height="33.2559"
				rx="7.50133"
				transform="matrix(-0.707107 0.707107 0.707107 0.707107 11.026 -2.94165)"
				fill="#005BBA"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M31.193 0.892589C30.2579 -0.0161405 28.7633 0.00520305 27.8545 0.940263L8.31856 21.0423C7.40983 21.9774 7.43117 23.472 8.36623 24.3808L8.73641 24.7405C8.90369 24.4944 9.18435 24.331 9.5045 24.3265C10.026 24.319 10.4548 24.7358 10.4622 25.2573C10.4668 25.5774 10.3115 25.8626 10.0703 26.0369L11.2401 27.1737C11.4084 27.0018 11.642 26.8937 11.9016 26.89C12.4231 26.8825 12.8519 27.2992 12.8593 27.8207C12.8631 28.0803 12.7617 28.3169 12.5946 28.49L13.6979 29.5623C13.8664 29.3888 14.101 29.2797 14.3619 29.2759C14.8834 29.2685 15.3122 29.6852 15.3197 30.2067C15.3234 30.4676 15.221 30.7053 15.0524 30.8786L16.3565 32.146C16.5267 31.9379 16.7843 31.8035 17.0742 31.7994C17.5957 31.7919 18.0245 32.2087 18.032 32.7302C18.0361 33.0201 17.9091 33.2814 17.706 33.4575L18.3554 34.0886C19.2904 34.9974 20.7851 34.976 21.6939 34.041L41.2298 13.9389C42.1386 13.0039 42.1172 11.5092 41.1822 10.6004L38.7404 8.2274C38.1062 9.2061 37.0108 9.86029 35.7572 9.87819C33.7616 9.90669 32.1208 8.31207 32.0923 6.31652C32.0744 5.0629 32.697 3.94928 33.6572 3.28743L31.193 0.892589Z"
				fill="#FDD500"
			/>
			<path
				d="M26.412 12.4535V12.4535C24.5893 15.8095 19.3945 15.1461 16.6861 12.4536V12.4536L21.5689 7.63875C21.5689 7.63875 22.3191 8.38897 26.412 12.4535Z"
				fill="black"
				fillOpacity="0.05"
			/>
			<path
				d="M26.1267 12.1516V12.1516C24.3927 15.3441 19.4915 14.7223 16.915 12.161L16.6865 11.9338L21.2835 7.33681C21.2835 7.33681 22.0338 8.08703 26.1267 12.1516Z"
				fill="#005BBA"
			/>
			<path
				d="M60.5531 28.7508C61.0045 28.7508 61.3807 28.9263 61.6816 29.2774C62.0077 29.6285 62.1707 30.0799 62.1707 30.6316C62.1707 31.1833 61.9575 31.6347 61.5312 31.9858C61.1299 32.3369 60.6033 32.5125 59.9513 32.5125H59.2741C58.0453 32.5125 56.9168 32.199 55.8886 31.5721C54.8855 30.92 54.0955 30.0423 53.5187 28.9389C52.9419 27.8354 52.6535 26.5941 52.6535 25.2148V15.9233H50.9232C50.3965 15.9233 49.9702 15.7729 49.6442 15.4719C49.3182 15.171 49.1552 14.7948 49.1552 14.3434C49.1552 13.8419 49.3182 13.4406 49.6442 13.1397C49.9702 12.8387 50.3965 12.6883 50.9232 12.6883H52.6535V7.27142C52.6535 6.71971 52.8291 6.2683 53.1802 5.91721C53.5313 5.56612 53.9827 5.39057 54.5344 5.39057C55.0861 5.39057 55.5375 5.56612 55.8886 5.91721C56.2397 6.2683 56.4152 6.71971 56.4152 7.27142V12.6883H59.6127C60.1393 12.6883 60.5657 12.8387 60.8917 13.1397C61.2177 13.4406 61.3807 13.8419 61.3807 14.3434C61.3807 14.7948 61.2177 15.171 60.8917 15.4719C60.5657 15.7729 60.1393 15.9233 59.6127 15.9233H56.4152V25.2148C56.4152 26.2179 56.6911 27.058 57.2428 27.7351C57.7945 28.4122 58.4716 28.7508 59.2741 28.7508H60.5531Z"
				fill="#575757"
			/>
			<path
				d="M85.3566 21.9045C85.3566 22.3809 85.1936 22.7822 84.8676 23.1082C84.5416 23.4091 84.1278 23.5596 83.6262 23.5596H69.3317C69.6076 25.3151 70.3975 26.732 71.7016 27.8103C73.0307 28.8887 74.6483 29.4279 76.5542 29.4279C77.3065 29.4279 78.084 29.2899 78.8865 29.0141C79.714 28.7382 80.3911 28.3997 80.9178 27.9984C81.2689 27.7226 81.6827 27.5846 82.1591 27.5846C82.6356 27.5846 83.0118 27.71 83.2877 27.9608C83.7391 28.337 83.9648 28.7633 83.9648 29.2398C83.9648 29.6912 83.7641 30.0674 83.3629 30.3683C82.5102 31.0454 81.4444 31.5971 80.1654 32.0235C78.9115 32.4498 77.7078 32.6629 76.5542 32.6629C74.4978 32.6629 72.6546 32.2241 71.0245 31.3463C69.3944 30.4435 68.1154 29.2022 67.1875 27.6223C66.2847 26.0423 65.8333 24.2493 65.8333 22.243C65.8333 20.2368 66.2597 18.4437 67.1123 16.8638C67.99 15.2588 69.1938 14.0174 70.7236 13.1397C72.2784 12.2369 74.0339 11.7855 75.9899 11.7855C77.921 11.7855 79.5886 12.2118 80.993 13.0645C82.3974 13.9171 83.4757 15.1083 84.2281 16.6381C84.9804 18.1678 85.3566 19.9233 85.3566 21.9045ZM75.9899 15.0205C74.1592 15.0205 72.6671 15.5221 71.5135 16.5252C70.385 17.5283 69.6703 18.87 69.3693 20.5502H81.8958C81.6701 18.87 81.0432 17.5283 80.015 16.5252C78.9868 15.5221 77.6451 15.0205 75.9899 15.0205Z"
				fill="#575757"
			/>
			<path
				d="M107.986 21.9045C107.986 22.3809 107.823 22.7822 107.497 23.1082C107.171 23.4091 106.757 23.5596 106.255 23.5596H91.9608C92.2366 25.3151 93.0266 26.732 94.3306 27.8103C95.6598 28.8887 97.2773 29.4279 99.1832 29.4279C99.9356 29.4279 100.713 29.2899 101.515 29.0141C102.343 28.7382 103.02 28.3997 103.547 27.9984C103.898 27.7226 104.312 27.5846 104.788 27.5846C105.265 27.5846 105.641 27.71 105.917 27.9608C106.368 28.337 106.594 28.7633 106.594 29.2398C106.594 29.6912 106.393 30.0674 105.992 30.3683C105.139 31.0454 104.073 31.5971 102.794 32.0235C101.541 32.4498 100.337 32.6629 99.1832 32.6629C97.1268 32.6629 95.2836 32.2241 93.6535 31.3463C92.0234 30.4435 90.7445 29.2022 89.8166 27.6223C88.9138 26.0423 88.4624 24.2493 88.4624 22.243C88.4624 20.2368 88.8887 18.4437 89.7413 16.8638C90.6191 15.2588 91.8228 14.0174 93.3526 13.1397C94.9074 12.2369 96.6629 11.7855 98.619 11.7855C100.55 11.7855 102.218 12.2118 103.622 13.0645C105.026 13.9171 106.105 15.1083 106.857 16.6381C107.609 18.1678 107.986 19.9233 107.986 21.9045ZM98.619 15.0205C96.7883 15.0205 95.2961 15.5221 94.1425 16.5252C93.014 17.5283 92.2993 18.87 91.9984 20.5502H104.525C104.299 18.87 103.672 17.5283 102.644 16.5252C101.616 15.5221 100.274 15.0205 98.619 15.0205Z"
				fill="#575757"
			/>
			<path
				d="M128.415 29.804C128.691 30.105 128.829 30.4812 128.829 30.9326C128.829 31.4843 128.603 31.9482 128.152 32.3244C127.776 32.6253 127.374 32.7758 126.948 32.7758C126.396 32.7758 125.945 32.5501 125.594 32.0987L118.447 23.2587L116.566 24.9138V30.6692C116.566 31.221 116.39 31.6724 116.039 32.0235C115.713 32.3495 115.274 32.5125 114.723 32.5125C114.171 32.5125 113.719 32.3495 113.368 32.0235C113.042 31.6724 112.879 31.221 112.879 30.6692V4.97678C112.879 4.42506 113.042 3.9862 113.368 3.66018C113.719 3.30909 114.171 3.13354 114.723 3.13354C115.274 3.13354 115.713 3.30909 116.039 3.66018C116.39 3.9862 116.566 4.42506 116.566 4.97678V20.2869L125.707 12.124C126.033 11.798 126.422 11.635 126.873 11.635C127.4 11.635 127.826 11.8356 128.152 12.2369C128.478 12.5629 128.641 12.9516 128.641 13.403C128.641 13.9296 128.44 14.356 128.039 14.682L121.118 20.8512L128.415 29.804Z"
				fill="#575757"
			/>
			<path
				d="M141.752 11.7855C143.733 11.7855 145.501 12.2369 147.056 13.1397C148.636 14.0174 149.864 15.2462 150.742 16.8262C151.645 18.4061 152.096 20.2117 152.096 22.243C152.096 24.2493 151.657 26.0423 150.78 27.6223C149.902 29.2022 148.673 30.4435 147.093 31.3463C145.538 32.2241 143.758 32.6629 141.752 32.6629C139.745 32.6629 137.952 32.2241 136.372 31.3463C134.792 30.4435 133.564 29.2022 132.686 27.6223C131.808 26.0423 131.369 24.2493 131.369 22.243C131.369 20.2117 131.808 18.4061 132.686 16.8262C133.564 15.2462 134.792 14.0174 136.372 13.1397C137.952 12.2369 139.745 11.7855 141.752 11.7855ZM141.752 15.171C140.473 15.171 139.319 15.4719 138.291 16.0738C137.288 16.6757 136.498 17.5158 135.921 18.5942C135.344 19.6725 135.056 20.8888 135.056 22.243C135.056 23.5972 135.344 24.8135 135.921 25.8919C136.498 26.9452 137.288 27.7727 138.291 28.3746C139.319 28.9765 140.473 29.2774 141.752 29.2774C143.031 29.2774 144.172 28.9765 145.175 28.3746C146.203 27.7727 146.993 26.9452 147.545 25.8919C148.121 24.8135 148.41 23.5972 148.41 22.243C148.41 20.8888 148.121 19.6725 147.545 18.5942C146.993 17.5158 146.203 16.6757 145.175 16.0738C144.172 15.4719 143.031 15.171 141.752 15.171Z"
				fill="#575757"
			/>
		</svg>
	)
}
