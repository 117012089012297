import React, { memo, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share'
import ReactTooltip from 'react-tooltip'
import { DateTime } from 'luxon'

import { getSubdomain } from 'apollo/utils'

import animatedScroll from 'helpers/animatedScroll'

import Dots from '../common/Dots/Dots'
import {
	CalendarIcon,
	FacebookIcon,
	GmailIcon,
	LinkidinIcon,
	LocationIcon,
	TelegramIcon,
	TwiterIcon,
	WhatsAppIcon
} from '../Icons/Icons'

import s from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)
const Index = ({
	begins: b,
	ends: e,
	name,
	address,
	timezoneField,
	position,
	description,
	slug,
	eventLanguage,
	rawDescription,
	locationName,
	isOnline,
	locationDescription,
	...props
}) => {
	const url = String(window.location)
	const [t] = useTranslation()
	const [hideCalendarTitle, setHideCalendarTitle] = useState(false)
	const begins = DateTime.fromISO(b, { zone: timezoneField })
	const ends = DateTime.fromISO(e, { zone: timezoneField })

	const [lng, lat] = (position && position.coordinates) || [0, 0]
	const origin = process.env.REACT_APP_API_ENDPOINT.slice(0, -1)

	const renderOnline = () => (
		<>
			<p data-for={'locationName'} style={{ cursor: 'pointer' }} data-tip>
				Online
			</p>
			{address && typeof address === 'string' && (
				<ReactTooltip
					id={'locationName'}
					className={cx('hideIpad')}
					type="success"
					place="left"
					effect="solid"
				>
					<h4>{address}</h4>
				</ReactTooltip>
			)}
			<div className={cx('showIpad')}>{address}</div>
		</>
	)

	const renderOffline = () => (
		<>
			<p style={{ cursor: 'pointer' }} data-for={'locationName'} data-tip>
				{locationName && <h4>{locationName}</h4>}
				<p>{address}</p>
			</p>
			{locationDescription && typeof locationDescription === 'string' && (
				<ReactTooltip
					id={'locationName'}
					className={cx('hideIpad')}
					type="success"
					place="left"
					effect="solid"
				>
					<p style={{ color: '#fff' }}>{locationDescription}</p>
				</ReactTooltip>
			)}
			<div className={cx('showIpad')}>
				<div className={cx('titleIpad')}>
					{!isOnline && (
						<strong className={cx('fw600')}>
							<Trans i18nKey={'event.locationTitle'} /> :{' '}
						</strong>
					)}
					<p>{locationName}</p>
				</div>

				{locationDescription && (
					<div className={cx('locationDescription', 'titleIpad')}>
						<Trans i18nKey={'event.locationDescription'} />:<p>{locationDescription}</p>
					</div>
				)}
			</div>
		</>
	)

	return (
		<div className={cx('wrap')}>
			<div className={cx('science')}>
				<h1 className={cx('title')}>{name}</h1>
				<div className={cx('items', 'fz1628')}>
					<article className={cx('item')}>
						<div className={cx('itemTitle')}>
							<CalendarIcon />
							<span>{t('event.dateAndTime')}</span>
						</div>
						<div className={cx('descr', 'descrLine', 'fz1628', 'fw600')}>
							<p>
								{begins.toFormat('HH:mm dd.MM')} - {ends.toFormat('HH:mm dd.MM')}
							</p>
						</div>

						<p
							onClick={() => setHideCalendarTitle(true)}
							className={`${cx('btn-orange', 'fz1628', 'fw600')} ${hideCalendarTitle ? 'dropdown' : ''}`}
						>
							{hideCalendarTitle ? (
								<ul className={cx('CalendarList')}>
									<li>
										<a href={`${origin}${props.gcalLink}`} target={'_blank'}>
											Google Calendar
										</a>
									</li>
									<li>
										<a href={`${origin}${props.icalLink}`} target={'_blank'}>
											Apple Calendar
										</a>
									</li>
								</ul>
							) : (
								<span>{t('event.addInCalendar')}</span>
							)}
						</p>
					</article>
					<article className={cx('item')}>
						<div className={cx('itemTitle')}>
							<LocationIcon />
							<span>{t('event.location')}</span>
						</div>

						<div className={cx('descr', 'fz1628', 'fw600')}>
							{isOnline ? renderOnline() : renderOffline()}
						</div>

						{lat !== 0 && lng !== 0 && !isOnline && (
							<a
								href={`http://maps.google.com/maps?z=12&t=m&q=${lat},${lng}`}
								target={'_blank'}
								className={cx('btn-orange', 'fz1628', 'fw600')}
							>
								{t('event.showOnMap')}
							</a>
						)}
					</article>
				</div>
				<div className={cx('decor')}>
					<Dots />
				</div>
			</div>
			<div className={cx('share')}>
				<h6 className={cx('share-title', 'fz1628', 'fw600')}>{t('event.shareEvent')}</h6>
				<div className={cx('share-links')}>
					<FacebookShareButton url={url}>
						<FacebookIcon />
					</FacebookShareButton>

					<a target={'_blank'} href={`https://telegram.me/share/url?url=${url}&text=${name}`}>
						<TelegramIcon />
					</a>
					<a target={'_blank'} href={`whatsapp://send?text=${url}`}>
						<WhatsAppIcon />
					</a>

					<a
						target={'_blank'}
						href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${name}`}
					>
						<LinkidinIcon />
					</a>
					<TwitterShareButton url={url}>
						<TwiterIcon />
					</TwitterShareButton>

					<EmailShareButton url={url}>
						<GmailIcon />
					</EmailShareButton>
				</div>
				<button onClick={() => animatedScroll('buy')} className={cx('btn-green', 'orange')}>
					{props.textButton}
				</button>
			</div>
		</div>
	)
}
export default memo(Index)
