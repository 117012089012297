import React, { FC, PropsWithChildren, useEffect, useState } from 'react'

import { CSSTransition } from 'react-transition-group'
import { CloseIcon } from 'pages/Event/Icons/Icons'

import s from './index.module.scss'

interface Props {
	isOpen: boolean
	close: () => void
	modalClassName?: string
	overlayClassName?: string
	timeout?: number
}

const ModalWrapper: FC<PropsWithChildren<Props>> = ({
	isOpen: isOpenProp,
	close,
	modalClassName,
	overlayClassName = '',
	timeout = 300,
	children
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const resetBodyStyles = () => {
		document.body.style.overflow = 'auto'
		document.body.style.paddingRight = '0px'
		document.onkeydown = null
	}
	useEffect(() => {
		setIsOpen(isOpenProp)

		if (isOpenProp) {
			document.body.style.overflow = 'hidden'
			document.body.style.paddingRight = '20px'
			document.onkeydown = (e) => {
				if (e.key === 'Escape') close()
			}
		} else {
			resetBodyStyles()
		}

		return resetBodyStyles
	}, [isOpenProp, close])

	const closeModal = () => {
		setIsOpen(false)
		setTimeout(() => {
			close()
		}, timeout)
	}
	const handleClose = (e: React.MouseEvent) => {
		e.preventDefault()
		closeModal()
	}

	return (
		<CSSTransition
			in={isOpen}
			timeout={timeout}
			classNames={{
				enter: s['Overlay-enter'],
				enterActive: s['Overlay-enter-active'],
				exit: s['Overlay-exit'],
				exitActive: s['Overlay-exit-active']
			}}
			unmountOnExit
		>
			<div onClick={closeModal} className={`${s.Overlay} ${overlayClassName}`}>
				<div className={`${s.Modal} ${modalClassName || ''}`} onClick={(e) => e.stopPropagation()}>
					<button className={'CloseBtn'} onClick={handleClose}>
						<CloseIcon fill="var(--accent-color)" />
					</button>

					{children}
				</div>
			</div>
		</CSSTransition>
	)
}

export default ModalWrapper
