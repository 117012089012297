export default {
	long: {
		en: 'English',
		uk: 'Ukrainian',
		ru: 'Russian',
		he: 'Hebrew',
		pl: 'Polish',
		lv: 'Latvian'
	},
	short: {
		en: 'Eng',
		uk: 'Ukr',
		ru: 'Ru',
		he: 'He',
		pl: 'Pol',
		lv: 'Lv'
	},
	/**
	 * remove
	 */
	en: 'English',
	uk: 'Ukrainian',
	ru: 'Russian',
	he: 'Hebrew',
	pl: 'Polish',
	lv: 'Latvian'
}
