import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import s from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

const SystemNotification = () => {
	const [t] = useTranslation()
	return (
		<div className={cx('Component')}>
			<div className={cx('container', 'Content')}>
				<p className={cx('Title')}>
					<Trans i18nKey={'whiteLabel.title'} components={[<strong />]} />
				</p>
				<a href="https://offer.teeko.io/" target="_blank" className={cx('ReadMore')}>
					{t('whiteLabel.readMore')}
				</a>
			</div>
		</div>
	)
}

export default SystemNotification
