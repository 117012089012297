export default {
	financeDeleted: 'Konto finansowe zostało usunięte',
	financeNotDeleted: 'Konto finansowe nie zostało usunięte',
	eventIsEmpty: 'To wydarzenie nie istnieje lub nie zostało opublikowane przez organizatora',
	ups: 'Ups ..... ',
	somethingWrong: 'Coś poszło nie tak',
	integrationMinLength: 'Kod jest za krótki. Minimalna liczba znaków musi być większa niż 5',
	inputFinance: 'Wprowadź szczegóły finansowe',
	slugExists: 'Ten ślimak już istnieje',
	enterSlug: 'Wchodzić slug',
	prevSlugNotAvailable: 'Należy pamiętać, że stary link do wydarzenia nie będzie dostępny.',
	'slug exists': 'Przepraszamy, nie możesz zapisać tego ślimaka, jest już zajęty',
	'User with this Alias already exists': 'Użytkownik z tym aliasem już istnieje.',

	fillDateLocationName: 'Wpisz nazwę miejsca i adres',
	fillAllDateLocationName: 'Wprowadź nazwę miejsca i adres we wszystkich językach',
	attemptsError: 'Przekroczono liczbę prób autoryzacji. Spróbuj ponownie za kilka sekund',
	'Please add phone number': 'Dodaj numer telefonu',
	'Please upload image': 'Prześlij obraz wydarzenia',

	'Organizer with this Name already exists': 'Nazwa kontaktu o tej nazwie już istnieje',
	'Organizer with this Email already exists': 'Osoba kontaktowa z tym adresem e-mail już istnieje',
	'Organizer with this Phone already exists': 'Osoba kontaktowa z tym numerem telefonu już istnieje',
	incorrectSmsCode: 'Kod jest niepoprawny lub wygasł',
	emailVerificateError:
		'Przepraszamy, ale link potwierdzenia nie jest już aktywny lub został już aktywowany. Spróbuj ponownie.',
	resetPasswordCodeError: 'Przepraszamy, ale łącze nie jest już aktywne. Spróbuj ponownie.',
	'Field is required': 'Pole wymagane',
	'Invalid credantails': 'Nieprawidłowe poświadczenia',
	'Invalid phone number': 'Nieprawidłowy numer telefonu',
	'Enter a valid email address': 'Nieprawidłowy adres e-mail',
	'Password must have at least 8 characters': 'Hasło musi zawierać co najmniej 8 znaków',
	'User with this Email already exists': 'Użytkownik z tym adresem e-mail już istnieje',
	'User with this Phone already exists': 'Użytkownik o tym numerze już istnieje',
	'You are already subscribed': 'Jesteś już subskrybowany',
	'Incorrect old password': 'Nieprawidłowe stare hasło',
	'Incorrect coupon': 'Nieprawidłowy kod promocyjny',
	"End date can't be later than event's end date":
		'Data zakończenia nie może być późniejsza niż data zakończenia wydarzenia (% {data})',
	"Start date can't be after end date": 'Data początkowa nie może być późniejsza niż data końcowa',
	'You cannot set start date in the past': 'Nie możesz ustawić daty początkowej w przeszłości',
	'You cannot set end date in the past': 'Nie można ustawić daty końcowej w przeszłości',
	'You cannot set end date before start': 'ВNie można ustawić daty końcowej na datę początkową',
	'Something went wrong, contact support': 'Coś poszło nie tak. Skontaktuj się z pomocą techniczną.',
	'Incorrect code': 'Niepoprawny kod. Ponownie zeskanuj kod QR',
	'You have not access, contact organizer':
		'Nie masz uprawnień do aktywacji biletu. Skontaktuj się z organizatorem ',
	'Attendee status is invalid': 'Nie można aktywować biletu. Bilet ma status: ',
	'You can not added role to yourself': 'Nie możesz przypisać sobie roli',
	'Invite already send': 'Zaproszenie zostało już wysłane',
	'Select available languages': 'Wybierz dostępne języki',
	'Error occurred, check fields': 'Wystąpił błąd. Sprawdź wprowadzone dane.',
	'No more tickets': 'Żadnych więcej biletów',

	'Coupon is inactive': 'Kod promocyjny jest nieaktywny',
	'Please fill in all fields': 'Proszę wypełnić wszystkie pola',
	'Please fill in all fields all lang': 'Proszę wypełnić wszystkie pola dla wszystkich języków',

	'Please add days': 'Dodaj dzień'
}
