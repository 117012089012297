import { gql } from '@apollo/client'

export const EDIT_GROUP_DISCOUNT = gql`
	mutation ($groupDiscount: GroupDiscountInput!, $groupDiscountId: ID!, $ticketTypesIds: [ID]!) {
		editGroupDiscount(
			groupDiscount: $groupDiscount
			groupDiscountId: $groupDiscountId
			ticketTypesIds: $ticketTypesIds
		) {
			ok
		}
	}
`

export const REMOVE_GROUP_DISCOUNT = gql`
	mutation ($groupDiscountId: ID!) {
		removeGroupDiscount(groupDiscountId: $groupDiscountId) {
			ok
		}
	}
`

export const REMOVE_AVATAR = gql`
	mutation removeAvatar {
		removeAvatar {
			ok
		}
	}
`
export const DELETE_EVENT = gql`
	mutation ($eventId: Int!) {
		deleteEvent(eventId: $eventId) {
			ok
		}
	}
`

export const SIGN_UP = gql`
	mutation ($language: LanguageEnum!, $signup: SignupInput!) {
		signup(signup: $signup, language: $language) {
			user {
				id
			}
		}
	}
`

export const SIGN_IN = gql`
	mutation ($email: String!, $password: String!) {
		tokenAuth(email: $email, password: $password) {
			token
		}
	}
`

export const CHANGE_LANGUAGE = gql`
	mutation ($language: LanguageEnum!) {
		setUserLanguage(language: $language) {
			ok
		}
	}
`

export const EDIT_PROFILE = gql`
	mutation ($user: UserInput!) {
		editUser(user: $user) {
			ok
		}
	}
`

export const UPLOAD_AVATAR = gql`
	mutation ($file: Upload!) {
		uploadAvatar(file: $file) {
			success
		}
	}
`

export const SUBSCRIBE = gql`
	mutation ($email: String!) {
		createSubscribe(email: $email) {
			ok
		}
	}
`

export const FORGOT_PASSWORD = gql`
	mutation ($email: String!) {
		forgotPassword(email: $email) {
			ok
		}
	}
`

export const CREATE_ORGANIZER = gql`
	mutation ($organizer: OrganizerInput!) {
		createOrganizer(organizer: $organizer) {
			organizer {
				id
			}
		}
	}
`

export const UPDATE_ORGANIZER = gql`
	mutation ($organizer: OrganizerInput!) {
		updateOrganizer(organizer: $organizer) {
			organizer {
				id
			}
		}
	}
`

export const CREATE_TICKET = gql`
	mutation ($ticketType: TicketTypeInput!) {
		createTicketType(ticketType: $ticketType) {
			ticketType {
				id
			}
		}
	}
`
export const CREATE_DAY = gql`
	mutation ($day: DayInput!) {
		createDay(day: $day) {
			day {
				id
				eventId
				created
				begins
				event {
					name
					id
					slug
				}
			}
		}
	}
`

export const UPDATE_DAY = gql`
	mutation ($day: UpdateDayInput) {
		updateDay(day: $day) {
			day {
				id
				modified
				eventId
			}
		}
	}
`
export const DELETE_DAY = gql`
	mutation ($id: ID) {
		deleteDay(id: $id) {
			ok
		}
	}
`
export const UPDATE_TICKET = gql`
	mutation ($ticketType: TicketTypeInput!) {
		updateTicketType(ticketType: $ticketType) {
			ticketType {
				id
			}
		}
	}
`

export const REMOVE_TICKET = gql`
	mutation ($id: Int!) {
		removeTicketType(id: $id) {
			ok
		}
	}
`
export const DELETE_ORGANIZER = gql`
	mutation ($id: ID!) {
		deleteOrganizer(id: $id) {
			ok
		}
	}
`

export const SET_GA = gql`
	mutation ($googleAnalytics: GoogleAnalyticsInput!) {
		setGa(googleAnalytics: $googleAnalytics) {
			ok
		}
	}
`

export const SET_FP = gql`
	mutation ($eventId: ID!, $scriptText: String!) {
		addPixelScript(eventId: $eventId, scriptText: $scriptText) {
			ok
		}
	}
`

export const UPLOAD_IMAGE = gql`
	mutation ($file: Upload!, $slug: String!) {
		uploadImage(file: $file, slug: $slug) {
			success
		}
	}
`

export const REMOVE_IMAGE = gql`
	mutation ($slug: String!) {
		removeImage(slug: $slug) {
			ok
		}
	}
`

export const UPLOAD_LOGO = gql`
	mutation ($file: Upload!, $id: ID!) {
		uploadLogo(file: $file, id: $id) {
			success
		}
	}
`

export const CREATE_SCAN = gql`
	mutation ($scan: ScanInput!) {
		createScan(scan: $scan) {
			scan {
				id
			}
		}
	}
`
export const UPDATE_FINANCE = gql`
	mutation ($finance: FinanceInput!) {
		updateFinance(finance: $finance) {
			ok
		}
	}
`

export const UPLOAD_SCAN = gql`
	mutation ($file: Upload!, $id: String!) {
		uploadScan(file: $file, id: $id) {
			success
		}
	}
`

export const CREATE_FINANCE = gql`
	mutation ($finance: FinanceInput!) {
		createFinance(finance: $finance) {
			finance {
				id
			}
		}
	}
`

export const REMOVE_FINANCE = gql`
	mutation ($id: Int!) {
		removeFinance(id: $id) {
			ok
		}
	}
`

export const CREATE_QUESTION = gql`
	mutation ($question: QuestionInput) {
		createQuestion(question: $question) {
			question {
				id
			}
		}
	}
`

export const UPDATE_QUESTION = gql`
	mutation ($question: QuestionInput) {
		updateQuestion(question: $question) {
			question {
				id
			}
		}
	}
`

export const REMOVE_QUESTION = gql`
	mutation ($id: Int!) {
		removeQuestion(id: $id) {
			ok
		}
	}
`

export const SEND_USER_EMAIL_AGAIN = gql`
	mutation ($id: Int!) {
		sendUserEmailAgain(id: $id) {
			ok
		}
	}
`

export const SEND_EMAIL_AGAIN = gql`
	mutation ($attendeeId: ID!, $newEmail: String!) {
		sendEmailAgain(attendeeId: $attendeeId, newEmail: $newEmail) {
			ok
		}
	}
`

export const SEND_PHONE_AGAIN = gql`
	mutation ($id: Int!) {
		sendAgainPhone(id: $id) {
			ok
		}
	}
`

export const CONFIRM_PHONE = gql`
	mutation ($code: String!, $id: Int!) {
		confirmPhone(code: $code, id: $id) {
			ok
		}
	}
`

export const CONFIRM_EMAIL = gql`
	mutation ($code: String!, $id: Int!) {
		confirmEmail(code: $code, id: $id) {
			ok
		}
	}
`

export const VERIFY_FORGOT_PASSWORD_CODE = gql`
	mutation ($code: String!, $id: Int!) {
		verifyForgotPasswordCode(code: $code, id: $id) {
			ok
		}
	}
`

export const CHANGE_FORGOTTEN_PASSWORD = gql`
	mutation ($code: String!, $id: Int!, $password: String!) {
		changeForgottenPassword(code: $code, id: $id, password: $password) {
			ok
		}
	}
`

export const CHANGE_PASSWORD = gql`
	mutation ($newPassword: String!, $oldPassword: String!) {
		changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
			ok
		}
	}
`

export const GENERATE_COUPONS = gql`
	mutation ($quantity: Int!, $rules: GenerateCouponsInput!) {
		generateCoupons(quantity: $quantity, rules: $rules) {
			ok
			couponType {
				id
			}
		}
	}
`

export const CREATE_COUPON = gql`
	mutation ($coupon: CreateCouponInput!) {
		createCoupon(coupon: $coupon) {
			ok
		}
	}
`

export const CREATE_GROUP_DISCOUNT = gql`
	mutation ($groupDiscount: GroupDiscountInput!, $ticketTypeIds: [ID]!) {
		createGroupDiscount(groupDiscount: $groupDiscount, ticketTypeIds: $ticketTypeIds) {
			ok
		}
	}
`

export const VERIFY_COUPON = gql`
	mutation ($coupon: String, $ticketType: Int) {
		verifyCoupon(coupon: $coupon, ticketType: $ticketType) {
			ok
		}
	}
`

export const ADD_COUPON_TYPE_TO_TICKET_TYPE = gql`
	mutation ($couponType: Int!, $ticketTypes: [Int]!) {
		addCouponTypeToTicketTypes(couponType: $couponType, ticketTypes: $ticketTypes) {
			ok
		}
	}
`

export const REMOVE_COUPON_TYPE = gql`
	mutation ($id: Int!) {
		removeCouponType(id: $id) {
			ok
		}
	}
`

export const UPDATE_COUPON = gql`
	mutation ($coupon: UpdateCouponInput!) {
		updateCoupon(coupon: $coupon) {
			ok
		}
	}
`

export const CREATE_ORDER = gql`
	mutation ($attendees: [AttendeeInput]!, $isSubscribed: Boolean) {
		createOrder(attendees: $attendees, isSubscribed: $isSubscribed) {
			ok
			order {
				id
				totalPrice
				orderType
			}
			checkoutUrl
			error {
				ticketType
				attendeeIndex
				question
				message
			}
		}
	}
`

export const CHECK_FACEBOOK_ID = gql`
	mutation ($facebookId: String!) {
		checkFacebookId(facebookId: $facebookId) {
			ok
		}
	}
`

export const FACEBOOK_TOKEN_AUTH = gql`
	mutation ($facebookToken: String!) {
		tokenAuthWithFb(facebookToken: $facebookToken) {
			token
		}
	}
`

export const TICKET_APPROVAL = gql`
	mutation ($approval: Boolean!, $attendeeId: ID!) {
		ticketApproval(approval: $approval, attendeeId: $attendeeId) {
			ok
		}
	}
`

export const ACTIVATE_ATTENDEE = gql`
	mutation ($code: String!) {
		activateAttendee(code: $code) {
			reason
			ok
			attendee {
				id
				tksource
				created
				ticketName
				finalPrice
				order {
					id
				}
				code
				firstName
				lastName
				email
				phone
				status
				begins
				ends
				coupon {
					code
				}
				discount
				discountType
				answerSet {
					id
					question {
						id
						name
						isDefault
					}
					answerText
				}
			}
		}
	}
`
/**
 * For desk page
 */
export const ACTIVATE_ATTENDEE_SMALL = gql`
	mutation ($code: String!) {
		activateAttendee(code: $code) {
			reason
			ok
			attendee {
				id
				code
				status
			}
		}
	}
`

export const DEACTIVATE_ATTENDEE = gql`
	mutation ($code: String!) {
		deactivateAttendee(code: $code) {
			ok
			attendee {
				code
				id
				status
			}
		}
	}
`

export const ADD_USER_TO_ROLE = gql`
	mutation ($contacts: ContactInput!, $eventId: ID!, $role: RoleEnum!) {
		addUserToRole(contacts: $contacts, eventId: $eventId, role: $role) {
			ok
		}
	}
`

export const SEND_SMS = gql`
	mutation ($attendeeCode: String!, $message: String!) {
		sendSmsToAttendee(attendeeCode: $attendeeCode, message: $message) {
			ok
		}
	}
`

export const CHANGE_USER_ROLE = gql`
	mutation ($aclId: ID!, $role: RoleEnum!) {
		changeUserRole(aclId: $aclId, role: $role) {
			ok
		}
	}
`
export const SEND_SMS_ALL = gql`
	mutation ($eventId: ID!, $message: String!, $statuses: [AttendeesStatusEnum], $ticketTypeId: ID) {
		sendMassSmsToAttendees(
			eventId: $eventId
			message: $message
			statuses: $statuses
			ticketTypeId: $ticketTypeId
		) {
			ok
		}
	}
`

export const REMOVE_USER_FROM_ROLE = gql`
	mutation ($aclId: ID!) {
		removeUserFromRole(aclId: $aclId) {
			ok
		}
	}
`

export const SIGN_NOT_REGISTERED = gql`
	mutation ($language: LanguageEnum, $signup: SignupNotRegisteredInput!, $token: String!) {
		signupNotRegistered(language: $language, signup: $signup, token: $token) {
			ok
			user {
				id
			}
		}
	}
`
