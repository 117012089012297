import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'

import { SystemNotification } from 'components'
import { HashLink } from 'components/HashLink'
import IconBurger from 'components/Icons/Burger'
import Logo from 'components/Icons/Logo'
import TicketBG from 'components/Icons/TicketBG'

import animatedScroll from 'helpers/animatedScroll'
import { getToken } from 'helpers/localStorage'

import { showSignModal } from 'store/Base/actions'

import LanguageSwitcher from '../LanguageSwitcher/index'
import NavBar from '../Navbar'

import s from './index.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

function Header() {
	const [open, setOpen] = useState(false)
	const [isScrolled, setIsScrolled] = useState(false)
	const [t] = useTranslation()
	const isAuth = !!getToken()
	const dispatch = useDispatch()

	const handleCreateEvent = useCallback(() => {
		const token = getToken()
		if (token) {
			dispatch(push('/dashboard'))
		} else {
			dispatch(showSignModal({ tab: 'login' }))
		}
	}, [dispatch])

	const isLanding = useSelector((state) => state.router.location.pathname === '/organizers')

	const handleScroll = () => {
		setIsScrolled(window.scrollY > 65)
	}
	useEffect(() => {
		window.onscroll = handleScroll
		return () => (window.onscroll = null)
	}, [])

	const toggleNavBar = () => {
		setOpen(!open)
	}
	const links = useMemo(
		() => [
			{
				title: t('landing.howItWorks.title'),
				to: '/organizers#how-it-works'
			},
			{
				title: t('landing.pricing.title'),
				to: '/organizers#pricing'
			},
			{
				title: t('landing.advantages.title'),
				to: '/organizers#advantages'
			}
		],
		[t]
	)

	return (
		<Fragment>
			<SystemNotification />
			<header className={cx('Component', isScrolled && 'Shadow')}>
				<div className={cx('Container')}>
					<Link className={cx('Logo')} to="/">
						<Logo />
					</Link>
					{isLanding && (
						<nav className={cx('Nav')}>
							{links.map((link) => (
								<HashLink key={link.to} scroll={(el) => animatedScroll(null, el)} to={link.to}>
									{link.title}
								</HashLink>
							))}
						</nav>
					)}
					<div className={cx('Actions')}>
						<LanguageSwitcher className={cx('LanguageSwitcher')} isShort={false} />
						<button onClick={handleCreateEvent} className={cx('TicketButton')}>
							<TicketBG size={270} />
							<span>{isAuth ? t('user.myEvents') : t('event.createEvent')}</span>
						</button>
						<button type="button" onClick={toggleNavBar} className={cx('SidebarBtn')}>
							<IconBurger />
						</button>
					</div>
				</div>
			</header>

			<NavBar isOpen={open} close={toggleNavBar} />
		</Fragment>
	)
}

export default memo(Header)
