export default {
  organizerLanding: 'Как это работает?',
  company: 'Компания',
  aboutUs: 'О нас',
  team: 'Команда',
  career: 'Карьера',
  contacts: 'Контакты',
  resources: 'Организаторам',
  terms: 'Условия использования',
  policy: 'Политика конфиденциальности',
  support: 'База Знаний',
  faq: 'FAQ',
  telegram: 'Связаться с нами в telegram',
  office: {
    title: 'Контакты',
    street: 'Шауль Харнам 6, 38',
    city: 'Петах-Тиква, Израиль',
    address: 'Петах-Тиква, Шауль Харнам 6, 38'
  }
}
