import React, { Suspense } from 'react'

import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import NiceModal from '@ebay/nice-modal-react'
import { ConnectedRouter } from 'connected-react-router'
import App from 'containers/App'

import 'assets/css/style.scss'

import { ApolloProvider } from '@apollo/client'
import client from 'apollo/'

import ErrorBoundary from 'components/ErrorBoundary'
import Preloader from 'components/Preloader'

import store, { history } from 'store/'

export default function Root() {
	return (
		<ErrorBoundary>
			<Suspense fallback={<Preloader />}>
				<ApolloProvider client={client}>
					<Provider store={store}>
						<ConnectedRouter history={history}>
							<Router history={history}>
								<NiceModal.Provider>
									<App history={history} />
								</NiceModal.Provider>
							</Router>
						</ConnectedRouter>
					</Provider>
				</ApolloProvider>
			</Suspense>
		</ErrorBoundary>
	)
}
