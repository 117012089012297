export default {
	addOwnLetter: 'Dodaj swój list',
	nameOwnLetter: 'Tytuł listu',
	needActiveFinance: 'Aby publikować, musisz mieć aktywne konto finansowe',

	needAddOrganizer: 'Należy dodać osobę kontaktową',

	descriptionOwnLetter: 'Opis listu',
	wantToAddDraft: 'To wydarzenie zostało opublikowane.',
	isDraftToAdd: 'Może chcesz to ukryć w szkicach?',
	timeWarningMessage:
		'Podczas ustawiania godziny należy pamiętać, że 00-00 to początek nowego dnia, a 23-59 to koniec dnia',
	locationOnMap: 'Lokalizacja na mapie:',

	yesDraft: 'Tak, schowaj się',
	yesPublish: 'Tak, opublikuj',
	wantToAddPublish:
		'To wydarzenie nie zostało opublikowane i jest widoczne tylko dla tych, którzy mają dostęp do teeko.',
	isPublish: 'Może chcesz opublikować wydarzenie?',
	withoutScript: 'Nie używaj tagu script',
	addTicket: 'Dodaj co najmniej jeden typ biletu do wysłania',

	participants: {
		labels: {
			orders: 'Zamówienia',
			addParticipant: 'Dodaj uczestnika',
			emailParticipant: 'Email członka',
			name: 'Nazwa',
			firstCol: 'Nazwa',
			email: 'Email',
			phone: 'Telefon',
			ticketName: 'Nazwa biletu',
			code: 'Kod',
			ticketTypePrice: 'Cena',
			createdFormat: 'Utworzono',
			statusTrans: 'Status',
			paymentType: 'Typ płatności',
			coupon: 'Kupon'
		},
		filter: {
			all: 'Wszystkie zamówienia',
			allEndOrders: 'Wszystkie zrealizowane zamówienia',
			allNotEndOrders: 'Niedokończone zamówienia',
			orderWithPromo: 'Zamówienia przy użyciu kodu promocyjnego',
			orderAwait: 'Zamówienia oczekujące na potwierdzenie'
		},
		searchPlaceholder: 'Nr zamówienia, nazwa, e-mail, numer telefonu',
		filterDateFrom: 'Wybierz okres (y):\n',
		filterDateTo: 'Przed:'
	},
	ticketsFieldsRequired:
		'Aby utworzyć nowe pytanie wróć do sekcji „Tworzenie biletów” i określ pola danych: nazwa biletu, cena dla wszystkich rodzajów biletów. ',

	beginsMoreEnds: 'Data rozpoczęcia wydarzenia jest późniejsza niż data zakończenia wydarzenia',
	beginsTimeMoreEnds:
		'Godzina rozpoczęcia wydarzenia jest późniejsza niż godzina zakończenia wydarzenia.',

	promo: {
		addNewPromo: 'Dodanie nowego kodu promocyjnego\n',
		addNewGroup: 'Dodanie rabatu grupowego\n',
		sumSale: 'Kwota rabatu\n',
		name: 'Nazwa',
		saleApply: 'Rabat obowiązuje przy zakupie\n',
		from: 'od',
		to: 'przed',
		maxQuantityUnlimited: 'Maksymalna liczba biletów objętych zniżką nie jest ograniczona\n',
		or: 'lub',
		allTypes: 'Wszystkie typy',
		promoHasGroup: 'Ten kod promocyjny jest kodem grupowym, rabat jest naliczany przy zakupie\n',
		tickets: 'bilety',
		dateActivate: 'Data aktywacji\n',
		dateDeactivate: 'Data dezaktywacji\n',
		timeActivate: 'Czas aktywacji\n',
		timeDeactivate: 'Czas dezaktywacji\n',
		promoUnlimited: 'Ten kod promocyjny nie ma ograniczeń w użyciu\n',
		thisPromoUse: 'Tego kodu promocyjnego można użyć\n',
		time: 'czas\n',
		nameDiscount: 'Nazwa',
		activeFrom: 'Ważne od',
		activeTo: 'Ważne do\n',
		promoIsEmpty: 'Lista Twoich kodów promocyjnych jest pusta',
		labels: {
			promo: 'Kod promocyjny\n',
			discount: 'Zniżka\n',
			groupDiscount: 'Rabat grupowy\n',

			code: 'Kod',
			prefix: 'Przedrostek',
			ticketTypes: 'Typ biletu',
			validTo: 'Ważne do',
			type: 'Typ',
			activations: 'Ilość',

			name: 'Nazwa',
			begins: 'Ważne od',
			ends: 'Ważne do',
			validFrom: 'Ważny'
		},
		validNone: 'Nieokreślony',
		f: 'Z',
		t: 'Do'
	},
	fieldEmptyDay1: 'Masz puste pola! Proszę kliknąć',
	fieldEmptyDay2: ' i wypełnij wszystkie wymagane pola (oznaczone gwiazdką). ',
	fieldEmptyForMultilanguage:
		'Pamiętaj, że masz wydarzenia wielojęzyczne i wymagane pola muszą być wypełnione dla wszystkich języków',

	uploadDocument: 'Przesyłanie dokumentów\n',
	orDropFileHere: 'Lub przeciągnij pliki tutaj\n',
	downloadFile: 'Przesyłanie pliku\n',
	incorrectFormat: 'Obraz musi być w formacie: jpeg, jpg, pdf',
	downloadDocument: 'Pobierz dokumenty',
	warningForQuestion:
		'Nie możesz zapisać nowego pytania bez wypełnienia wszystkich wymaganych pól (oznaczonych gwiazdką).\n' +
		'\n' +
		'Być może zapomniałeś określić pole „Pytanie” dla innego języka',
	youChoice: 'Ty wybrałeś dzień',
	undoChange: 'Anuluj zmianę',
	saveChange: 'Zapisz zmianę',
	promoIncorrect: 'promo źle',
	nameQuestion: 'Tytuł pytania',
	actions: 'Działać',
	events: 'Wydarzenia',

	deleteContact: 'Usuń kontakt',
	editContact: 'Edytuj kontakt',
	contactTitle: 'Osoby kontaktowe',
	responseFor: 'Odpowiedzialny za',
	selectContact: 'Wybierz kontakt',
	goodJob: 'Świetna robota! Jesteś prawie gotowy.',
	goodJobEditPage: 'Dobra robota! Zapisz zmiany.',

	goodJobDescription:
		'Aktywuj swoje wydarzenie, zarządzaj sprzedażą biletów i śledź ją za pomocą raportów i analiz w czasie rzeczywistym.',
	additionalPhone: 'Dodatkowy numer telefonu',
	linkFacebook: 'Link do facebook',
	additionalContact: 'Dodatkowy kontakt',
	addNewContact: 'Dodaj kontakt',

	creatingEvent: 'Tworzenie wydarzenia',
	editEvent: 'Edycja wydarzeń',
	creatingEventDescription:
		'Wpisz informacje o swoim wydarzeniu, wypełnij odpowiednie pola i kliknij przycisk "zapisz"',
	commonInfo: 'Informacje ogólne',
	addImageEvent: 'Dodaj obraz tła wydarzenia',
	uploadImage: 'Prześlij obraz',
	recommendEventImage: 'zalecany rozmiar to 665 x 350, pliki muszą być w formatach: jpeg, jpg, pdf',
	orDropHere: 'Lub przeciągnij tutaj obrazek',
	dateAndLocation: 'Data i lokalizacja',
	oneLocation: 'Jedna lokalizacja na wszystkie dni',
	eventOnline: 'Event online',
	addDay: 'Dodaj dzień',
	day: 'Dzień',
	selectDateStart: 'Data zdarzenia (początek)',
	selectTimeStart: 'Czas zdarzenia (początek)',
	selectTimeEnd: 'Czas zdarzenia (koniec)',
	selectTimeZone: 'Wybierz strefę czasową',
	settingFinance: 'Założenie konta do wypłaty pieniędzy',
	ddmmyy: 'dd/mm/rr',
	createTicket: 'Utwórz bilety',
	nameTicket: 'Nazwa biletu',
	quantityTicket: 'QUANTITY',
	priceTicket: 'Cena',
	actionsTicket: 'ACTIONS',
	ticketWithAgree: 'Bilet potwierdzony (bilet można kupić tylko za potwierdzeniem od organizatora)',
	simplePay: 'Kup wiele biletów na jeden profil',
	ticketColor: 'Kolor biletu',
	hideSetting: 'Ukryj ustawienia',
	selectStartProfit: 'Data rozpoczęcia sprzedaży',
	selectEndProfit: 'Data zakończenia sprzedaży',
	selectTimeStartProfit: 'Czas rozpoczęcia sprzedaży',
	selectTimeEndProfit: 'Czas zakończenia sprzedaży',
	registrationFormTitle: 'Formularz rejestracyjn',

	eventIsEmpty: 'Lista wydarzeń jest pusta',
	incorrectSymbol: 'Nieprawidłowy symbol!',

	from: 'z ',
	add: 'Dodaj',
	integrationModalTitle: 'Dodaj',
	inputGA: 'Wpisz swój kod Google Analytics, aby otrzymywać statystyki zdarzeń:',
	inputFP: 'Wpisz swój kod Facebook Pixel, aby otrzymywać statystyki zdarzeń:',
	moveFile: 'Przeciągnij pliki tutaj lub',
	fileFormat: 'Pliki muszą mieć formaty: jpeg, jpg, pdf',
	myLetter: 'Chcę mój tekst na list',
	popup: {
		messageFirst: 'Pomyślnie zarejestrowałeś się na to wydarzenie.',
		messageSecond: 'Proszę sprawdzić swoją skrzynkę pocztową',
		messageThree: ': przychodzące, promocyjne, spam'
	},
	errorMessage: {
		ticketsAreBought: 'Nie możesz usunąć wydarzenia. Bilety już zakupione.',
		eventDelete: 'Wydarzenie zostało pomyślnie usunięte.',
		eventDontDelete: 'Wydarzenie nie zostało usunięte.'
	},
	registrationForm: {
		selectTypeQuestion: 'Wybierz rodzaj pytania',
		typeText: 'Pole tekstowe',
		typeOptions: 'Opcje odpowiedzi',
		typeFiles: 'Pole pliku',
		description: 'Pytania oznaczone gwiazdką będą wymagane do wypełnienia formularza rejestracyjnego',
		modal: {
			title: 'Dodanie nowego pytania ',
			withVariant: 'z opcjami odpowiedzi',
			withFile: 'z polem pliku',
			inputQuestion: 'Wpisz pytanie',
			selectTypeTickets: 'Wybierz typy biletów',
			requiredQuestion: 'Pytanie jest wymagane i zostanie oznaczone',
			inputOwnAnswer: 'Wpisz swoją odpowiedź'
		}
	},

	subscribe: 'Zapisz się do newslettera teeko',
	create: 'Utwórz wydarzenie',
	duplicate: 'Duplikat',
	created: 'Wydarzenie utworzone',
	generatingPayment: 'Oczekuj, wygeneruj formę płatności',
	edited: 'Zmieniono wydarzenie',
	createEvent: 'Utwórz wydarzenie',
	isDraft: 'Szkic',
	drafts: 'Szkice',
	draft: 'W wersji roboczej',
	currency: 'Waluta',
	published: 'Opublikowane',
	pastEvents: 'Przeszłe wydarzenia',
	eventsArchive: 'zarchiwizowane wydarzenia',
	modify: 'Szafka na imprezy',
	view: 'Zobacz',
	preview: 'Podgląd',
	publish: 'Publikuj',
	description: 'Opis',
	shading: 'Dodaj zaciemnienie na obrazie zdarzenia',
	addEventImg: 'Dodaj obraz tła wydarzenia',
	eventImgRecommended: 'Zalecany rozmiar: 665x350',
	starts: 'Początek wydarzenia',
	ends: 'Koniec wydarzenia',
	startsSales: 'Początek sprzedaży',
	endsSales: 'Koniec sprzedaży',
	title: 'Nazwa wydarzenia',
	location: 'Lokalizacja',
	locationTitle: 'Nazwa lokalizacji',
	locationDescription: 'Opis lokalizacji',
	dateAndTime: 'Data i godzina',
	addInCalendar: 'Pievienot kalendāram',
	shareEvent: 'Udostępnij wydarzenie znajomym:',
	organizerName: 'Osoba kontaktowa',
	organizerSpecialization: 'Jakie pytanie należy rozwiązać',
	newOrganizer: 'Nowy kontakt',
	editOrganizer: 'Zmień osobę kontaktową',

	addLogo: 'Dodaj logo',
	tickets: 'Bilety',
	ticketsDelete: 'Bilet został pomyślnie usunięty',
	isDeleteTicket: 'Usunąć bilet',
	organizerText:
		'Możesz dodać wszystkie inne informacje o swojej organizacji po utworzeniu lub możesz',
	doItNow: 'Zrób to teraz',
	users: 'użytkownicy',
	ticketss: 'bilety',
	ticketsTitle: 'Kody promocyjne i rabaty',
	registrationTitle: 'Formularz rejestracyjny',
	participantsTitle: 'Członkowie',
	billingTitle: 'Informacje rozliczeniowe',
	integrationsTitle: 'Integracja',
	statisticTitle: 'Statystyki',
	accessTitle: 'Prawa dostępu',
	ticketsSold: 'Bilety wyprzedane',
	totalIncome: 'Całkowity dochód online',
	phoneNumber: 'Numer telefonu',
	agree: 'Zgadzam się z ',
	agreeUse: 'warunkami użytkowania ',
	agreeAnd: 'i ',
	agreePolicy: 'polityką prywatności',
	showOnMap: 'pokazać na mapie',
	totalAmount: 'Łączna kwota:',

	delete: 'Usuń wydarzenie',
	eventCart: {
		breadCrumb: {
			event: 'Wydarzenia'
		},
		tabs: {
			all: 'Wszyscy',
			active: 'Aktywny',
			passed: 'Przeszłość',
			draft: 'Wersja robocza'
		},
		title: 'Zarządzanie zdarzeniami',
		contactPerson: 'Twarze kontaktowe',
		contact: 'kontakt'
	},

	questions: {
		'First Name': 'Imię',
		'Last Name': 'Nazwisko',
		'Phone Number': 'Numer telefonu',
		Email: 'Email'
	},
	showResult: 'Zobacz zamówienie',
	sendLetterAgain: 'Wyślij ponownie email potwierdzający',
	attendees: {
		addForm: {
			title: 'Dodaj członka',
			selectTicketType: 'Wybierz typ biletu:',
			price: 'Płatna kwota'
		},
		sendAll: 'Wyślij do wszystkich',
		send: 'Wyślij',
		activate: 'Aktywuj',
		deactivate: 'Dezaktywuj',
		refund: 'Zwróć pieniądze',
		activation: 'Aktywacja biletu',
		base: 'Baza uczestników',

		enterTextSms: 'Wprowadź SMS tekstowy',
		status: 'Status',
		price: 'Płatny',
		ticketPrice: 'Cena biletu',
		date: 'Data',
		questionnaire: 'Kwestionariusz',
		statuses: {
			UNFINISHED: 'Limit czasu płatności',
			DECLINED: 'Potwierdzenie odrzucone',
			PAID: 'Pomyślnie zapłacono',
			REGISTERED: 'Zarejestrowany',
			ERROR: 'Błąd płatności',
			DEACTIVATED: 'Dezaktywowane',
			ACTIVATED: 'Aktywowany',
			AWAITING_PAYMENT: 'Płatność oczekująca',
			AWAITING_APPROVE: 'Oczekiwanie na potwierdzenie',
			ARCHIVED_UNFINISHED: 'Zarchiwizowane'
		},
		filters: {
			ALL: 'Wszystko',
			WEEK: 'Ostatni tydzień',
			TODAY: 'Na dzisiaj',
			PAID: 'Płatny',
			DECLINED: 'Nieopłacone',
			APPROVE: 'Oczekiwanie na potwierdzenie'
		},
		actions: 'Akcje',
		approve: 'Potwierdź',
		decline: 'Odmówić'
	},
	ticket: {
		inputInfo: 'Wprowadź poniżej informacje o gościu',
		important:
			'Ważne: jeśli nie dodasz biletów, impreza jest bezpłatna i nieograniczona liczba odwiedzających',
		newType: 'Nowy typ biletu',
		free: 'Wolny',
		name: 'Nazwa biletu',
		date: 'Data',
		quantity: 'Ilość',
		ticket: 'Bilet',
		price: 'Cena',
		discount: 'Zniżka',
		discountIncluded: 'Gratulacje, zniżka grupowa jest wliczona, to cię uratowało',
		saved: 'Gratulacje, zaoszczędziłeś dzięki kodowi promocyjnemu',
		moreTicket: 'Jeszcze jeden bilet',
		addCoupon: 'Dodaj kod promocyjny',
		addDiscount: 'Dodaj zniżkę grupową',

		addDescription: 'Dodaj opis',
		simpleCoupon: 'Normalny kod promocyjny',
		multipleCoupon: 'Wiele kodów promocyjnych',
		promocode: 'Promocode',
		remainingTickets: 'Zostały bilety:',
		total: 'Razem:',
		end: 'Bilety się skończyły',
		salesEnd: 'Sprzedaż się skończyła',
		signupTickets: 'Zarejestruj bilety:',
		buy: 'Kup',
		pay: 'Do zapłaty',
		reg: 'Zarejestruj się',
		buyTicket: 'Kup bilety',
		buyTickets: 'Kup bilety:',
		advancedSettings: 'Ustawienia zaawansowane',
		withApprove: 'Z potwierdzeniem',
		simplifiedPurchase: 'Włącz uproszczony zakup',
		uploadPicture: 'Prześlij zdjęcie'
	},
	registration: {
		mainDescription: 'Opis przed formularzem rejestracyjnym',
		addQuestion: 'Dodaj pytanie',
		editQuestion: 'Zmień pytanie',
		newQuestion: 'Nowe pytanie',
		question: 'Pytanie',
		type: 'Typ',
		required: 'Wymagane pytanie',
		forSomeTypes: 'Na bilety',
		edit: 'Zmień',
		hint: 'Podpowiedź',
		create: 'Utwórz',
		simpleText: 'Linia wejściowa',
		bigText: 'Pole tekstowe',
		select: 'Lista',
		dropdown: 'Lista wyszukiwania',
		options: 'Opcje odpowiedzi',
		addOption: 'Dodaj opcję',
		questionPriority: 'Numer porządkowy pytania'
	},
	integrations: {
		text: 'Tutaj możesz dodać własne skrypty',
		ga: 'Wprowadź kod Google Analytics, aby uzyskać statystyki zdarzeń',
		fp: 'Facebook Pixel'
	},
	access: {
		header: 'Tutaj możesz zarządzać dostępem do wydarzeń innych osób.',
		items: [
			'- Współorganizator (ma dostęp do edycji wszystkiego jako założyciel, ale nie może usunąć wydarzenia).',
			'- Sprzedaż (ma dostęp do listy uczestników wydarzenia, może przesłać do xlsx, csv) i może zmienić identyfikator Google Analytics.',
			'- Wolontariusze (mają dostęp do listy uczestników i aktywacji biletów przy wejściu do dni imprezy).'
		],
		add: 'Dodaj osobę',
		role: 'Rola',
		roleType: {
			OPERATOR: 'Operator',
			CO_ORGANIZER: 'Współorganizator',
			SALES: 'Dział sprzedaży',
			VOLUNTEER: 'Wolontariusz'
		},
		statusType: {
			ADDED: 'Dodano',
			INVITED: 'Zaproszono'
		},
		name: 'Imię',
		phone: 'Telefon',
		status: 'Status',
		user: 'Użytkownik',
		actions: 'Akcje',
		invite: 'Zaproś',
		edit: 'Edytuj',
		remove: 'Usuń',
		save: 'Zapisz',
		cancel: 'Anuluj'
	},
	billing: {
		createBilling: {
			text: 'Proszę wypełnić swój pierwszy',
			link: 'informacje finansowe',
			orWait: 'lub poczekaj na potwierdzenie'
		},
		chooseBilling: 'Wybierz i zapisz konto',
		hideAll: 'Ukryj wszystko',
		showAll: 'Pokaż wszystko'
	},
	payment: {
		PENDING: 'Twoja płatność została wysłana, proszę czekać na potwierdzenie.',
		OK: 'Gratulacje, Twoja płatność została zaakceptowana!',
		FAILURE: 'Płatność nie została zaakceptowana, skontaktuj się z pomocą techniczną.',
		downloadTickets: 'Pobierz bilety',
		registratedSuccessful: 'Gratulacje, rejestracja zakończona pomyślnie!',
		awaitingApprove:
			'Niektóre bilety muszą zostać sprawdzone. Poczekaj na list pod wskazany adres e-mail '
	},
	creatingHint: 'Najpierw utwórz wydarzenie',
	save: 'Zapisz',
	organizerOf: 'Osoba kontaktowa',

	mainSettings: {
		title: 'Podstawowe ustawienia'
	},
	locationSettings: {
		title: 'Ustawienia lokalizacji'
	},
	languageSettings: {
		title: 'Ustawienia języka',
		isMultilanguage: 'Wielojęzyczne wydarzenie',
		selectAvailable: 'Wybierz dostępne języki',
		selectDefault: 'Wybierz swój podstawowy język'
	},
	hint: {
		previous_text: 'Poprzednio został wprowadzony: ',
		is_multilanguage_changed:
			'<0> Uwaga:.. </0> \nNie zmienił nalewka multilanguage to wydarzenie, bo z tego, co to wydarzenie zostało wysłane w przeciągu \nSprawdź dla żądanego tłumaczenia w sobitiya, biletów i pytania zawarte w formularzu rejestracyjnym \n Następnie publikują ponownie.'
	}
}
