import React, { Fragment, memo, useState } from 'react'
import Header from '../Landing/Header/index'
import Footer from 'components/Footer'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import Button from '../Landing/Button'
import Form from './Form'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { showSignModal } from 'store/Base/actions'
import EventCard from 'components/EventCard'
import { useQuery } from '@apollo/client'
import POSTER_EVENTS from 'apollo/queries/POSTER_EVENTS'
import { PosterEvents, PosterEventsVariables } from 'apollo/__generated_types__/PosterEvents'
import { PosterSortingEnum } from 'apollo/__generated_types__/globalTypes'
import Loader from 'components/Loader'
import { getToken } from 'helpers/localStorage'
import { push } from 'connected-react-router'
import NotFound from '../NotFound/NotFound'

const cx = classNames.bind(styles)

function PostersPage({ openSignModal }: ReturnType<typeof mapDispatch> & any) {
	const [countEdges, setEdges] = useState(9)
	const [t] = useTranslation()
	const {
		loading,
		error,
		data: events,
		refetch
	} = useQuery<PosterEvents, PosterEventsVariables>(POSTER_EVENTS, {
		variables: {
			withDescription: false,
			sort: PosterSortingEnum.START,
			first: countEdges
		}
	})
	const totalCount = events?.posterEvents?.totalCount ?? 0
	const handleRefetch = () =>
		refetch({ first: countEdges, withDescription: false, sort: PosterSortingEnum.START })
	const showMore = () => {
		if (!totalCount) return
		if (totalCount > countEdges) {
			setEdges((prevState) => +prevState + 6)
			handleRefetch()
		}
	}
	if (error) {
		// @ts-ignore
		return <NotFound isEmpty={true} />
	}

	return (
		<Fragment>
			<Header />
			<main className={cx('Page')}>
				<Intro />
				<Form />
				<section className={cx('EventSection')}>
					{loading && (
						<div className={cx('Loader')}>
							<Loader />
						</div>
					)}
					{!loading && events && (
						<ul className={cx('EventList')}>
							{events.posterEvents &&
								events.posterEvents.edges.map((event) => {
									if (!event || !event.node) return
									return <EventCard key={event.node.id} {...event.node} />
								})}
						</ul>
					)}

					{totalCount > countEdges && (
						<Button text="postersPage.moreEvents" className={cx('EventBtn')} onClick={showMore} />
					)}
				</section>

				<section className={cx('Propose')}>
					<h4 className={cx('ProposeText')}>{t('postersPage.propose')}</h4>
					<Button className={cx('ProposeBtn')} onClick={openSignModal} text="event.createEvent" />
				</section>
			</main>

			<Footer />
		</Fragment>
	)
}

const mapDispatch = (dispatch: Dispatch) => ({
	openSignModal: () => {
		const token = getToken()
		if (token) {
			dispatch(push('/dashboard'))
		} else {
			dispatch(showSignModal({ tab: 'login' }))
		}
	}
})

export default connect(null, mapDispatch)(memo(PostersPage))

const Intro = () => {
	const [t] = useTranslation()
	return (
		<section className={cx('Intro')}>
			<h1 className={cx('IntroTitle')}>{t('postersPage.description')}</h1>
			<div>
				<img src={require('assets/img/mascotte/tickets.svg')} alt="" />
			</div>
		</section>
	)
}
