export default {
	long: {
		en: 'Англійська',
		uk: 'Українська',
		ru: 'Російська',
		he: 'Іврит',
		pl: 'Польська',
		lv: 'Латвійська'
	},
	short: {
		en: 'Анг',
		uk: 'Укр',
		ru: 'Рос',
		he: 'Івр',
		pl: 'Пол',
		lv: 'Лат'
	},
	en: 'Англійська',
	uk: 'Українська',
	ru: 'Російська',
	he: 'Іврит',
	pl: 'Польська',
	lv: 'Латвійська'
}
